import React from 'react';
import { connect } from 'react-redux';
import { Router } from '@reach/router';
import styled, { css } from 'styled-components';
import { Authenticator, SignUp, Greetings } from 'aws-amplify-react';

import CustomTheme from 'components/LoginTheme';
import { Container } from 'components/Layout/Grid';

import { AUTHED_ROUTES } from 'helper/constants/constants';
import AmplifyDict from 'helper/localization/LoginI18n/AmplifyDict.js';
import { tinyPhone } from 'helper/constants/mediaRules';

import Profile from './dashboard/Profile';
import SignOut from './dashboard/SignOut';

import SEO from '../components/Seo';

const DashboardContainer = styled(Container)`
  ${tinyPhone(css`
    padding-left: 10px;
    padding-right: 10px;
  `)}
`;

const StyledRouter = styled(Router)`
  width: 100%;
`;

const AuthStateComponents = ({ user, authState }) => {
  if (authState === 'signedIn' && user.attributes.email) {
    return (
      <>
        <DashboardContainer>
          <StyledRouter basepath={process.env.GATSBY_PATH_PREFIX || ''}>
            <Profile path={AUTHED_ROUTES.dashboard} />
            <SignOut path={AUTHED_ROUTES.signout} />
          </StyledRouter>
        </DashboardContainer>
      </>
    );
  }
  return null;
};

const Dashboard = ({ user }) => (
  <Authenticator hide={[SignUp, Greetings]} theme={CustomTheme}>
    <SEO title="Login" />
    <AuthStateComponents user={user} />
  </Authenticator>
);

export default connect(({ user }) => ({ user }))(Dashboard);

AmplifyDict();
