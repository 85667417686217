import { I18n } from '@aws-amplify/core';

const AmplifyDict = () => {
  const dict = {
    en: {
      'Sign In': 'Log in',
      'Sign in to your account': 'Log in to your account',
      'Back to Sign In': 'Back to Log In',
      'Not yet registered?': 'Not yet registered? ',
      'Request access.': 'Request access',
      'Username/client id combination not found.': 'Incorrect username / email entry',
      'User does not exist': 'Incorrect user email or password',
      'Custom auth lambda trigger is not configured for the user pool.': 'Incorrect user email or password',
      'Network error': 'Connection Error',
      'Family Name': 'Last Name',
      'Given Name': 'First Name',
    },
    de: {
      'Sign In': 'Anmelden',
      'Sign in to your account': 'Melden Sie sich auf Ihrem Konto',
      'Back to Sign In': 'Zurück zur Anmeldung',
      'Not yet registered?': 'Noch nicht registriert? ',
      'Request access.': 'Zugang beantragen',
      'Username/client id combination not found.': 'Falscher Benutzername / E-Mail',
      'User does not exist': 'E-Mail oder Passwort falsch',
      'Custom auth lambda trigger is not configured for the user pool.': 'E-Mail oder Passwort falsch',
      'Network error': 'Netzwerkverbindungsfehler',
      'Family Name': 'Nachname',
      'Given Name': 'Vorname',
      'Signed out': 'Glerble',
      'Redirecting to home...': 'Berble',
    },
    fr: {
      'Sign In': 'Ouvrir une session',
      'Sign in to your account': 'Connectez-vous à votre compte',
      'Back to Sign In': 'Revenir à Log In',
      'Not yet registered?': 'Pas encore inscrit? ',
      'Request access.': 'Demander l\'accès',
      'Username/client id combination not found.': 'Nom d\'utilisateur / email incorrect',
      'User does not exist': 'Email ou mot de passe incorrect',
      'Custom auth lambda trigger is not configured for the user pool.': 'Email ou mot de passe incorrect',
      'Network error': 'Erreur de connexion réseau',
      'Family Name': 'Nom de Famille',
      'Given Name': 'Prénom',
    },
    es: {
      'Sign In': 'Inicia sesión',
      'Sign in to your account': 'Inicie sesión en su cuenta',
      'Back to Sign In': 'Volver a iniciar sesión',
      'Enter your username': 'Introduzca su nombre de usuario',
      'Enter your password': 'Introduzca su contraseña',
      'Forget your password? ': '¿Olvidó su contraseña? ',
      'Reset password': 'Restablecer la contraseña',
      'Reset your password': 'Restablecer su contraseña',
      'Not yet registered?': '¿Todavía no se ha registrado? ',
      'Request access.': 'Solicite acceso',
      'Username/client id combination not found.': 'Nombre de usuario/correo electrónico incorrecto',
      'User does not exist': 'Correo electrónico o contraseña del usuario incorrectos',
      'Custom auth lambda trigger is not configured for the user pool.': 'Correo electrónico o contraseña del usuario incorrectos',
      'Network error': 'Error de conexión de red',
      'Family Name': 'Apellido',
      'Given Name': 'Nombre de pila',
    },
    it: {
      'Sign In': 'Accedi',
      'Sign in to your account': 'Accedi al tuo account',
      'Back to Sign In': 'Torna all\'accesso',
      'Forget your password? ': 'Dimenticate la password? ',
      'Not yet registered?': 'Non sei ancora iscritto? ',
      'Request access.': 'Richiedi accesso',
      'Username/client id combination not found.': 'Inserimento errato del nome utente/e-mail',
      'User does not exist': 'Email o password utente errata',
      'Custom auth lambda trigger is not configured for the user pool.': 'Email o password utente errata',
      'Network error': 'Errore di connessione di rete',
      'Family Name': 'Cognome',
      'Given Name': 'Nome di battesimo',
    },
    ja: {
      'Sign In': 'ログインする',
      'Sign in to your account': 'あなたのアカウントにログイン',
      Username: 'ユーザー名',
      Password: 'パスワード',
      'Back to Sign In': 'ログインに戻る',
      'Enter your username': 'ユーザー名を入力して下さい',
      'Enter your password': 'パスワードを入力してください',
      'Forget your password? ': 'パスワードをお忘れですか？',
      'Reset password': 'パスワードを再設定する',
      'Reset your password': 'あなたのパスワードをリセット',
      'Send Code': 'コードを送る',
      Code: 'コード',
      'Resend Code': 'コードを再送信',
      Submit: '差し出す',
      'New Password': '新しいパスワード',
      'Not yet registered?': 'まだ登録されていませんか？',
      'Request access.': 'アクセスをリクエスト',
      'Username/client id combination not found.': '間違ったユーザー名/メールエントリ',
      'User does not exist': '間違った電子メールまたはパスワード',
      'Custom auth lambda trigger is not configured for the user pool.': '間違った電子メールまたはパスワード',
      'Network error': '接続エラー',
      'Family Name': '苗字',
      'Given Name': 'ファーストネーム',
    },
    ko: {
      'Sign In': '로그인',
      'Sign in to your account': '귀하의 계정에 로그인하십시오',
      Username: '사용자 이름',
      Password: '암호',
      'Back to Sign In': '로그인으로 돌아 가기',
      'Enter your username': '사용자 이름을 입력하십시오',
      'Enter your password': '비밀번호를 입력하십시오',
      'Forget your password? ': '비밀번호를 잊어 버렸습니까? ',
      'Reset password': '암호를 재설정',
      'Reset your password': '비밀번호를 재설정',
      'Send Code': '코드를 보내다',
      Code: '암호',
      'Resend Code': '코드 재전송',
      Submit: '제출하다',
      'New Password': '새 비밀번호',
      'Not yet registered?': '아직 등록하지 않으셨습니까? ',
      'Request access.': '접근 요청',
      'Username/client id combination not found.': '잘못된 사용자 이름 / 이메일 입력',
      'User does not exist': '잘못된 사용자 이메일 또는 비밀번호',
      'Custom auth lambda trigger is not configured for the user pool.': '잘못된 사용자 이메일 또는 비밀번호',
      'Network error': '연결 오류',
      'Family Name': '성',
      'Given Name': '이름',
    },
    ru: {
      'Sign In': 'Авторизоваться',
      'Sign in to your account': 'Вход в свой аккаунт',
      Username: 'имя пользователя',
      Password: 'пароль',
      'Back to Sign In': 'вернуться к логину',
      'Enter your username': 'Введите имя пользователя',
      'Enter your password': 'введите ваш пароль',
      'Forget your password? ': 'Забыли свой пароль? ',
      'Reset password': 'Сброс пароля',
      'Reset your password': 'сбросить пароль',
      'Send Code': 'Отправить код',
      Code: 'код',
      'Resend Code': 'Отправить код еще раз',
      Submit: 'Разместить',
      'New Password': 'новый пароль',
      'Not yet registered?': 'еще не зарегистрированы? ',
      'Request access.': 'Запрос доступа',
      'Username/client id combination not found.': 'Неверное имя пользователя / адрес электронной почты',
      'User does not exist': 'Неверный адрес электронной почты или пароль пользователя',
      'Custom auth lambda trigger is not configured for the user pool.': 'Неверный адрес электронной почты или пароль пользователя',
      'Network error': 'Ошибка подключения',
      'Family Name': 'фамилия',
      'Given Name': 'Имя',
    },
    zh: {
      'Sign In': '登录',
      'Sign in to your account': '登录到您的帐户',
      'Back to Sign In': '返回至登录页面',
      'Enter your username': '输入用户名',
      'Enter your password': '输入密码',
      'Forget your password? ': '忘记了密码？',
      'Reset password': '重设密码',
      'Reset your password': '重设您的密码',
      'Not yet registered?': '尚未注册？',
      'Request access.': '请求访问',
      'Username/client id combination not found.': '错误的用户名/邮箱',
      'User does not exist': '用户邮箱或密码不正确',
      'Custom auth lambda trigger is not configured for the user pool.': '用户邮箱或密码不正确',
      'Network error': '网络连接错误',
      'Family Name': '姓氏',
      'Given Name': '名字',
    },
  };

  return (
    I18n.putVocabularies(dict)
  );
};

export default AmplifyDict;
