import {
  COLOR_CTA_TEXT,
  COLOR_FORM_FIELD_BG,
  FONT_SIZE_DOCS_COPY,
  FONT_SIZE_ROOT,
  FORM_ERROR_RED,
  MEDIUM_STONE,
  MONA_LISA_PINK,
  JAVA_GREEN,
} from 'helper/constants/styles';
import strings from 'helper/localization/localization.js';

// export const Container = {};
export const FormContainer = {
  marginTop: '5%',
  marginLeft: '10px',
  marginRight: '10px',
};
export const FormSection = {
  backgroundColor: `${MEDIUM_STONE}`,
  boxShadow: '0 20px 10px #020A1029',
  maxWidth: '28.75rem',
};
export const SectionHeader = {
  color: '#ffffff',
  text: `${strings.signInTitle}`,
};
export const SectionBody = {
  marginBottom: '0',
};
export const SectionFooter = {
  fontSize: `${FONT_SIZE_ROOT}`,
  color: '#ffffff',
  flexDirection: 'column-reverse',
};
// export const SectionFooterPrimaryContent = {};
export const SectionFooterSecondaryContent = {
  marginBottom: '1rem',
};
// export const FormField = {};
export const Input = {
  fontSize: `${FONT_SIZE_DOCS_COPY}`,
  border: 'transparent 1px solid',
  borderRadius: '10px',
  padding: '0.625rem 1.25rem',
  backgroundColor: `${COLOR_FORM_FIELD_BG}`,
  marginBottom: '1.7rem',
  marginTop: '1rem',
  color: '#ffffff',
};
export const InputLabel = {
  color: '#ffffff',
  fontSize: `${FONT_SIZE_DOCS_COPY}`,
};
export const Hint = {
  fontSize: `${FONT_SIZE_ROOT}`,
  marginTop: '1.4rem',
  marginBottom: '1rem',
  color: '#ffffff',
};
export const Button = {
  color: `${COLOR_CTA_TEXT}`,
  backgroundColor: `${MONA_LISA_PINK}`,
  fontSize: `${FONT_SIZE_DOCS_COPY}`,
  padding: '0.66rem 2rem',
  fontWeight: '700',
  borderRadius: '6px',
  minWidth: '8.25rem',
  touchAction: 'manipulation',
  userSelect: 'none',
  textTransform: 'capitalize',
};
// export const PhotoPickerButton = {};
// export const PhotoPlaceholder = {};
// export const SignInButton = {};
// export const SignInButtonIcon = {};
// export const SignInButtonContent = {};
// export const Strike = {};
// export const StrikeContent = {};
// export const ActionRow = {};
// export const FormRow = {};
export const A = {
  color: `${JAVA_GREEN}`,
  textDecoration: 'none',
};
// export const Radio = {};
// export const Label = {};
// export const AmazonSignInButton = {};
// export const FacebookSignInButton = {};
// export const GoogleSignInButton = {};
// export const OAuthSignInButton = {};
export const Toast = {
  boxShadow: '0 -3px 6px rgba(0,0,0,0.16),0 -3px 6px rgba(0,0,0,0.23)',
  zIndex: 1000,
  position: 'fixed',
  display: 'flex',
  bottom: 0,
  left: 0,
  right: 0,
  top: 'auto',
  color: `${FORM_ERROR_RED}`,
  fontSize: `${FONT_SIZE_DOCS_COPY}`,
  backgroundColor: `${COLOR_FORM_FIELD_BG}`,
  padding: '1.48rem 18px',
};
export const ToastBox = {
  alignItems: 'center',
  boxSizing: 'border-box',
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingLeft: '18px',
  paddingRight: '18px',
  display: 'flex',
  maxWidth: '1200px',
  width: '100%',
};
export const ToastText = {
  marginLeft: '2.2rem',
  paddingTop: 0,
  marginBottom: 0,
};
// export const NavBar = {};
// export const NavRight = {};
// export const Nav = {};
// export const NavItem = {};
// export const NavButton = {};

const AmplifyTheme = {
  // container: Container,
  formContainer: FormContainer,
  formSection: FormSection,
  // formField: FormField,

  sectionHeader: SectionHeader,
  sectionBody: SectionBody,
  sectionFooter: SectionFooter,
  // sectionFooterPrimaryContent: SectionFooterPrimaryContent,
  sectionFooterSecondaryContent: SectionFooterSecondaryContent,

  input: Input,
  button: Button,
  // photoPickerButton: PhotoPickerButton,
  // photoPlaceholder: PhotoPlaceholder,
  // signInButton: SignInButton,
  // signInButtonIcon: SignInButtonIcon,
  // signInButtonContent: SignInButtonContent,
  // amazonSignInButton: AmazonSignInButton,
  // facebookSignInButton: FacebookSignInButton,
  // googleSignInButton: GoogleSignInButton,
  // oAuthSignInButton: OAuthSignInButton,

  // formRow: FormRow,
  // strike: Strike,
  // strikeContent: StrikeContent,
  // actionRow: ActionRow,
  a: A,

  hint: Hint,
  // radio: Radio,
  // label: Label,
  inputLabel: InputLabel,
  toast: Toast,
  toastBox: ToastBox,
  toastText: ToastText,

  // navBar: NavBar,
  // nav: Nav,
  // navRight: NavRight,
  // navItem: NavItem,
  // navButton: NavButton,
};

export default AmplifyTheme;
